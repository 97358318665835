import "./index.scss";

function App() {
  return (
    <section className="App">
      <div className="container">
        <div className="containerBox slide-in-top">
          <a
            href="https://www.instagram.com/helt.pink"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1>helt.pink</h1>
          </a>
        </div>
      </div>
    </section>
  );
}

export default App;
